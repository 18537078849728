const wrapXElement = ( newText ) => {
	const disclaimer = document.querySelector( '.disclaimer' );
	const disclaimerText = disclaimer.innerHTML;
	console.log( disclaimerText );
	const newDisclaimer = disclaimerText.replace( '{XXX}', newText );
	disclaimer.innerHTML = newDisclaimer;
}

const updateDisclaimerPrice = ( value ) => {
	const disclaimer = document.querySelector( '.disclaimer' );
	const dynamicPrice = disclaimer.querySelector( '[data-dynamic-price]' );
	dynamicPrice.textContent = value;
}

// Get the current clicked radio button in our pricing table.
const isGetStartedPage = document.querySelector( '.get-started-page' );

if ( isGetStartedPage ) {
	wrapXElement( '<span data-dynamic-price></span>' );
	const planLink = document.querySelector( '[data-plan-link]' );
	const radioButtons = document.querySelectorAll( '[type="radio"]' );
	radioButtons.forEach( radioButton => {
		radioButton.addEventListener( 'change', () => {
			const urlElement = radioButton.closest( '[data-plan-id]' );
			const url = urlElement.getAttribute( 'data-url' );
			const planId = urlElement.getAttribute( 'data-plan-id' );
			const planLength = urlElement.getAttribute( 'data-plan-length' );
			const priceWithFrequency = urlElement.querySelector( '[data-price]' ).textContent;
			// planLink.href = url;
			planLink.href = `https://physiqonomics.staging.wpengine.com/payment?plan=${planLength}&plan_id=${planId}`
			// updateDisclaimerPrice( priceWithFrequency );
		} );
	} );
}
// Adjust the button link based on which button is checked.
