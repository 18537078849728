const prev = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39 39"><circle cx="19.5" cy="19.5" r="19" fill="#fff" stroke="#ff9d6c" stroke-miterlimit="10" /><polyline points="23.556 7.388 11.444 19.5 23.556 31.612" fill="none" stroke="#ff9d6c" stroke-linecap="round" stroke-miterlimit="10" /></svg>';
const next = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39 39"><circle cx="19.5" cy="19.5" r="19" fill="#fff" stroke="#ff9d6c" stroke-miterlimit="10" /><polyline points="15.444 7.388 27.556 19.5 15.444 31.612" fill="none" stroke="#ff9d6c" stroke-linecap="round" stroke-miterlimit="10" /></svg>';

document.addEventListener( 'DOMContentLoaded', () => {

	const testimonials = document.getElementById( 'testimonials-carousel' );

	if ( testimonials ) {
		const testimonialsCarousel = new Carousel( {
			elem: '#testimonials-carousel',
			autoplay: true,
			infinite: true,
			interval: 12000,
			initial: 0,
			dots: true,
			arrows: false,
			buttons: false,
			btnStopText: 'Pause',
			arrPrevText: prev,
			arrNextText: next
		} );
	}

	const onboardingCarousels = document.querySelectorAll( '[data-onboarding-carousel]' );

	if ( onboardingCarousels ) {
		onboardingCarousels.forEach( ( onboardingCarousel, index ) => {
			const newOnboardingCarousel = new Carousel( {
				elem: `[data-onboarding-carousel="${ index + 1 }"]`,
				autoplay: true,
				infinite: true,
				interval: 12000,
				initial: 0,
				dots: true,
				arrows: false,
				buttons: false,
				btnStopText: 'Pause',
				arrPrevText: prev,
				arrNextText: next
			} );
		} );
	}

} );

/**
 * Makes the slider responsive.
 * Recalculates slide widths based on the dynamic container.
 *
 * @param {boolean} resize Whether or not the calculation is triggered from a resize event.
 */
function calculateSlideWidth( resize = false ) {

	const carousels = document.querySelectorAll( '[data-carousel]' );
	carousels.forEach( carousel => {
		const width = carousel.clientWidth;
		const firstSlide = carousel.querySelector( '[data-carousel-slide]' );
		const slides = carousel.querySelectorAll( '[data-carousel-slide]' );
		slides.forEach( slide => {
			slide.style.width = `${width}px`;
		} );
		if ( resize ) {
			firstSlide.style.marginLeft = `-${width}px`;
		}
	} );

}

calculateSlideWidth();

window.addEventListener( 'resize', () => {
	calculateSlideWidth( true );
} );
